html,
body {
  padding: 0;
  margin: 0;
}

textarea {
  font-family: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.react-datepicker__input-container input {
  padding: 18.5px 14px;
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
  border-radius: 4px;
  box-shadow: none;
  height: 1.1876em;
  box-sizing: content-box;
}

.react-datepicker__day--selected {
  background-color: #0b85fe !important;
}

.slick-dots {
  padding-top: 20px;
  bottom: -30px;
}

.slick-dots li {
  margin: 0;
}

.slick-dots li button:before {
  color: #323C65;
  font-size: 12px;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
}
